* {
  box-sizing: border-box;
}
@font-face {
  font-family: AlfaSlabOne-Regular;
  src: url(./fonts/AlfaSlabOne-Regular.ttf);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* commons */

button {
  width: 100%;
  height: 100%;
  font-size: inherit;
}

.selectBox {
  width: fit-content;
  font-size: 2vmin;
}
.NoMargin {
  margin: 0;
  display: flex;
  justify-content: center;
  text-align: center;
}
/*nav bar styles*/
.nav {
  background-color: transparent;
  display: flex;
  color: whitesmoke;
  justify-content: space-between;
  align-items: stretch;
  gap: 0;
  padding: 0 1rem;
}
ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
}
a {
  font-size: 2vmin;
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 3vmin;
}

a.site-Title {
  font-size: 5vmin;
}

.nav ul .active {
  background-color: teal;
}
/* backgrounds*/
.backgroundMain {
  background-image: url(../public/water1.webp);
  min-height: 100vh;
  min-width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 0%;
  left: 0%;
  z-index: -1;
}
.backgroundSecond {
  background-image: linear-gradient(to bottom right, #696969, #00ffff);
  min-height: 100vh;
  min-width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 0%;
  left: 0%;
  z-index: -1;
}
.backgroundThird {
  background-image: linear-gradient(to bottom right, #696969, #696969);
  min-height: 100vh;
  min-width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 0%;
  left: 0%;
  z-index: -1;
}
/* centered text with a smaller style */
.mainTitle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
}
.bigTitle {
  font-family: AlfaSlabOne-Regular;
  font-size: 6vmin;
  display: flex;
  text-shadow: 0.1rem 0.1rem #00ffff, -0.1rem -0.1rem #00ffff,
    -0.1rem 0.1rem #00ffff, 0.1rem -0.1rem #00ffff;
}
.smallerText {
  font-size: 3vmin;
  text-align: center;
  color: whitesmoke;
}

/* footer*/
.footer {
  font-size: 2vmin;
  color: whitesmoke;
  padding: 0 1rem;
  position: fixed;
  bottom: 0%;
  align-items: center;
  display: flex;
}
.footer a {
  padding: 0 0;
}

/* this years ski pic*/
.skiPic {
  left: 20%;
  width: 13vh;
  border: 0.2vmin solid black;
}

/* color choosing selector*/
.picBox {
  font-size: 2.1vmin;
  font-weight: bold;
  position: absolute;
  top: 25%;
  left: 50%;
}

.ofs {
  background-color: red;
  width: fit-content;
  padding: 0 1vmin;
  text-align: center;
}
.yayin {
  background-color: green;
  width: fit-content;
  padding: 0 1vmin;
  text-align: center;
}

/* sign up loging title*/
.formContainer .ant-form .signLog {
  width: 65vw;
  font-size: 4vmin;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}
.formContainer .ant-form .signLog a {
  font-size: inherit;
  padding: 1vw;
}
/*login/signup form*/
.formContainer {
  display: flex;
  justify-content: center;
  padding: 3vmin;
}
.formContainer .ant-form {
  position: relative;
  height: 75vh;
  width: 75vw;
  padding: 5vw;
  background-color: #696969;
  border-radius: 5vmin;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.formContainer .ant-form .ant-form-item {
  height: fit-content;
  margin: 0;
  display: flex;
  justify-content: flex-end;
}
.formContainer .ant-form-item .ant-form-item-label {
  line-height: 3vmin;
}
.formContainer .ant-form-item .ant-btn {
  font-size: 3vmin;
  border-radius: 3vmin;
  height: 6vmin;
  min-height: 32px;
  width: 65vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.formContainer .ant-form .ant-form-item .ant-form-item-label {
  display: flex;
  padding: 0;
  flex-basis: auto !important;
  padding-right: 3vw;
  width: 32.5vw;
  flex-direction: row-reverse;
  align-items: center;
  text-align: right;
}
.formContainer .ant-form-item-label > label {
  font-size: 3vmin;
}
.formContainer .ant-input {
  width: 32.5vw;
  max-height: 25px;
}
.formContainer .ant-form-item-control-input-content {
  display: flex;
}
.formContainer .ant-input-affix-wrapper {
  width: 32.5vw;
  max-height: 25px;
}
.formContainer .ant-row {
  flex-flow: nowrap;
}
.formContainer .ant-form-horizontal .ant-form-item-control {
  flex: 1 1 !important;
}
.formContainer .ant-form a {
  padding: 1vw;
  height: fit-content;
  align-self: center;
  font-size: 3vmin;
}
.formContainer .signError {
  color: #3a0002;
  font-size: 4vmin;
  align-self: center;
  font-weight: bold;
  margin: 0;
}
/*Extras page stuffs*/
.extras {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  padding: 1.5vmin;
}
.interactions {
  display: flex;
  gap: 3vmin;
}

.item {
  display: flex;
  padding: 1.5vmin;
  justify-content: space-between;
}
.info {
  display: flex;
  flex-direction: column;
  width: 62.5vw;
  padding-right: 12.5vw;
}
.ant-input-number {
  font-size: 2vmin;
  width: 6vw;
  height: fit-content;
}
.interactions .ant-btn-primary {
  height: 100%;
}
.ant-btn-primary > a:only-child {
  padding: 0;
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: inherit;
}
.cartImg {
  padding: 1.5vmin;
}
.cartStripe {
  display: flex;
  align-items: center;
}
.infoCart {
  display: flex;
  flex-direction: column;
  width: 50vw;
}
.inline {
  display: flex;
}
.fullWidth {
  width: 100%;
}
/*Stripe Form*/
.stripeBox {
  background-color: white;
  width: 50vw;
  padding: 2.5vw;
  margin: 2.5vw;
}
.ElementsApp input {
  height: fit-content;
  font-size: 2vmin;
}
.totalPrice {
  display: flex;
  justify-content: flex-end;
}
/*<Form.Item
        label="Address"
        name="address"
      >
        <Input
          required
          type="text"
          placeholder="Address"
        />
      </Form.Item>

      <Form.Item
        label="City"
        name="city"
      >
        <Input
          required
          type="text"
          placeholder="City"
        />
      </Form.Item>
      <Form.Item
        label="Province"
        name="province"
      >
        <Input
          required
          type="text"
          placeholder="Province"
        />
      </Form.Item>
      */
